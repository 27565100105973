export default {
    state: {
        isHome: false,
    },
    mutations: {
        setHome(state, isHome) {
            state.isHome = isHome;
        },
    },
    actions: {
        getHome({ commit }, value) {
            commit('setHome', value);
        },
        // async getHome({ commit }) {
        //     try {
        //         const data = await reqGetSystem();
        //         commit('setSystem', data.data);
        //         return data.data;
        //     } catch (e) {
        //         return Promise.reject(e);
        //     }
        // },
    },
    getters: {},
    namespaced: true,
};
