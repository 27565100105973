function getUrl(url) {
    return 'http://pic.pusite.icu/' + url;
}

function group(array, subGroupLength) {
    var index = 0;
    var newArray = [];

    while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
    }

    return newArray;
}

export { getUrl, group };
