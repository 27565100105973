export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/index/index.vue'),
        meta: {
            isHidden: false,
        },
    },
    {
        path: '/type/:id',
        name: 'type',
        component: () => import('@/views/type'),
        meta: {
            isHidden: false,
        },
    },
    {
        path: '/publish',
        name: 'publish',
        component: () => import('@/views/publish'),
        meta: {
            isHidden: false,
        },
    },
    {
        path: '/info/:id',
        name: 'info',
        component: () => import('@/views/info'),
        meta: {
            isHidden: false,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login'),
        meta: {
            isHidden: true,
        },
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('@/views/search'),
    },
    {
        path: '/my',
        name: 'my',
        component: () => import('@/views/me'),
        children: [
            {
                //欢迎页
                path: '',
                name: 'me',
                meta: {
                    title: '我的资料',
                    keepalive: true,
                },
                component: () => import('@/views/me/components/me.vue'),
            },
            {
                //欢迎页
                path: '/myPublish',
                name: 'myPublish',
                meta: {
                    title: '我的发布',
                    keepalive: true,
                },
                component: () => import('@/views/me/components/myPublish.vue'),
            },
        ],
    },
];
