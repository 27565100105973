<template>
  <div>
    <header :class="{ black: !isHome }">
      <div class="main">
        <div style="display: flex; align-items: center">
          <div class="logo-div">
            <a href="/">
              <img
                v-if="system.system_logo"
                v-lazy="getUrl(system.system_logo)"
                alt=""
            /></a>
          </div>
          <ul class="menu">
            <li><a href="">首页</a></li>
            <li v-for="item in typeList" :key="item.id">
              <router-link :to="'/type/' + item.id">{{
                item.type_name
              }}</router-link>
            </li>
          </ul>
        </div>
        <ul class="login-ul">
          <li class="publish">
            <a-icon type="diff" />
            <router-link to="/publish">信息发布</router-link>
          </li>

          <li v-if="!userInfo">
            <a href="javascript:void(0)" @click="login(true)">登录</a>
          </li>

          <li v-if="!userInfo">
            <a href="javascript:void(0)" @click="login(false)">注册</a>
          </li>
          <li v-if="userInfo">
            <a href="javascript:void(0)" @click="logout()">退出登录</a>
          </li>
          <li v-if="userInfo">
            <a href="javascript:void(0)" @click="$router.push('/my')"
              >个人中心</a
            >
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import bus from "@/eventBus";
export default {
  name: "Header",
  computed: {
    ...mapState("types", ["typeList"]),
    ...mapState("user", ["userInfo"]),
  },
  data() {
    return {
      isHome: false,
      isLogin: false,
      system: [],
    };
  },
  created() {
    bus.$on("isHome", (val) => {
      this.isHome = val;
    });
    this.getInfo();
  },

  methods: {
    ...mapActions("system", ["getSystem"]),
    ...mapActions("user", ["logOut"]),
    login(isL) {
      this.$router.push({ params: { isLogin: isL }, name: "login" });
    },
    async getInfo() {
      this.system = await this.getSystem();
    },
    async logout() {
      const data = await this.logOut();
      if (data.code == 200) {
        this.$message.success(data.msg);
      } else {
        return this.$message.error(data.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.anticon-diff {
  margin-right: 5px;
}
@mainWidth: 80vw;
@colorblue: rgb(65, 161, 224);
@colorWhite: #fff;
@mobile: ~"(max-width: 767px)";
@tablet:~ "only screen and (min-width: 768px) and (max-width: 991px)";
@desktop: ~"only screen and (min-width: 992px) and (max-width: 1199px)";
@desktop-xl: ~"only screen and (min-width: 1200px) and (max-width: 1350px)";
li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.publish {
  background-color: #fdd000;
  border-radius: 5px;
  padding: 5px 20px;
  box-sizing: border-box;
  display: inline-block;
  a {
    color: #000 !important;
  }
}
@import url("@/style/global.less");
.black {
  border-bottom: 1px solid #eee;
  .login-ul {
    a {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
  .menu {
    a {
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
}
header {
  height: 100px;
  background-color: transparent;
  position: relative;
  z-index: 111;
  @media @mobile {
    height: 200px;
  }
  .main {
    width: @mainWidth;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-div {
      height: 49px;
      width: 123px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .menu {
      display: flex;
      margin-left: 200px;
      @media @mobile {
        margin-left: 30px;
      }
      li {
        padding: 10px 4px;
        margin-right: 30px;
        border-bottom: 2px solid transparent;
        @media @mobile {
          a {
            font-size: 16px;
          }
        }
        a {
          color: @colorWhite;
        }
        &:hover {
          border-bottom: 2px solid @colorblue;
        }
      }
    }
    .login-ul {
      display: flex;
      margin-right: 20px;
      li {
        margin-right: 10px;
        a {
          font-size: 12px;
          color: @colorWhite;
          @media @mobile {
            a {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>