<template>
  <div class="main">
    <main>
      <div class="left">
        <ul>
          <li>
            <div class="item">
              <div class="title">
                <h3>关于我们 <span>/about us</span></h3>
              </div>
              <ul class="article-ul">
                <li class="article">
                  <a href="javascript:void(0)" @click="show(7)">公司介绍</a>
                </li>

                <li class="article">
                  <a href="javascript:void(0)" @click="show(39)">收费模式</a>
                </li>

                <!-- <li class="article">
                  <a href="javascript:void(0)" @click="show(7)">公司介绍</a>
                </li>

                <li class="article">
                  <a href="javascript:void(0)" @click="show(7)">公司介绍</a>
                </li> -->
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="right">
        <div class="company" v-if="system">
          <p>公司地址：{{ system.system_address }}</p>
          <p>邮箱：{{ system.system_email }}</p>
          <p>电话：{{ system.system_phone }}</p>
        </div>
        <!-- <div class="qrcode">
          <img
            src="http://x252.www.pusite.icu/img/qrcode.17e3804d.png"
            alt=""
          />
          <p>扫码联系我</p>
        </div> -->
      </div>
    </main>
    <footer v-if="system">
      <p>{{ system.system_copyright }}</p>
      <p>
        备案号:<a target="_block" href="https://beian.miit.gov.cn/">
          {{ system.system_icp }}</a
        >
      </p>
    </footer>
    <a-modal
      :title="title"
      :visible="visible"
      :footer="null"
      @cancel="handleCancel"
    >
      <div v-html="content"></div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { reqGetArticle } from "@/api";
export default {
  name: "Footer",
  computed: {
    ...mapState("system", ["system"]),
  },
  data() {
    return {
      title: "",
      visible: false,
      content: "",
    };
  },

  mounted() {},

  methods: {
    async show(id) {
      this.visible = true;
      const res = await reqGetArticle({ id });
      if (res.code == 200) {
        this.content = res.data[0]["content"];
        this.title = res.data[0]["title"];
      }
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
footer {
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px;
  p {
    text-align: center;
  }
}
.main {
  background-color: #fff;
  main {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 40px;
    box-sizing: border-box;
    .left {
      width: 25%;
      border-right: 1px solid #eee;
      .title {
        h3 {
          font-size: 24px;
          font-weight: 600;
          span {
            font-size: 14px;
          }
        }
      }
      .article-ul {
        li {
          padding: 10px 0px;
          a {
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
    .right {
      width: 75%;
      display: flex;
      .company {
        width: 70%;
        padding: 40px;
        box-sizing: border-box;
        p {
          color: rgba(0, 0, 0, 0.9);
          font-weight: 600;
          font-size: 18px;
        }
      }
      .qrcode {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 120px;
          height: 120px;
        }
        p {
          color: #000;
          font-weight: 600;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>