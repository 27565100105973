import Vue from 'vue';
import Vuex from 'vuex';
import header from './modules/header';
import types from './modules/typeList';
import user from './modules/user';
import system from './modules/system';
Vue.use(Vuex);

// 新建并暴露store
export default new Vuex.Store({
    modules: {
        header,
        types,
        user,
        system,
    },
});
